import React from "react"
import { Link } from "gatsby"
import { useColorMode } from "theme-ui"

const Nav = ({ props }) => {

  const [ mode, setMode ] = useColorMode()
  const modes = [
    'light',
    'dark',
    'darker',
  ]

  const index = modes.indexOf(mode)
  const next = modes[(index + 1) % modes.length]

  return (
    <nav>
    <Link className="smiley" to="/">smile</Link>
    <button class={mode} title={next}
      {...props}
      onClick={e => {
        setMode(next)
      }}
    >
    switch
    </button>
    </nav>
  )
}

export default Nav