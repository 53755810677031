import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <div id='foot-wrap'>
    <footer>
      <nav>
        <span class="contact">Say Hello, <a href="/">hello@tylergardosh.com</a></span>
        <a href="https://www.linkedin.com/in/tylergardosh" class="linkedin"><FontAwesomeIcon aria-label="LinkedIn" icon={faLinkedinIn} /></a>
      </nav>
    </footer>
  </div>
)

export default Footer