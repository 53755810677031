import React from "react"
import { graphql } from "gatsby"
import Nav from "./nav"
import Footer from "./footer"
import "../normalize.css"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  let header

  if (isRootPath) {
    header = (
      
      <div id="wrap" class="home">
        <header>
        <Nav></Nav>
          <section>
            <h4>Hello, my name is</h4>
            <h1>Tiger Garbage, I’m a digital designer & creative director living in Brooklyn.</h1>  
          </section>
        </header>
      </div>
    )

  } else {
    header = (
      <span></span>
    )
  }

  return (
    <div data-is-root-path={isRootPath}>
      {header}
      {children}
      <Footer></Footer>
    </div>
  )
}

export default Layout

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          brandColor
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
